* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    text-decoration: none;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
ol,
ul {
    list-style: none;
}
del {
    text-decoration: line-through;
}
body {
    font-family: "poppins_regular";
    font-size: 17px;
    color: #1f3347;
    overflow-x: hidden;
    min-width: 320px;
    font-weight: 500;
}
body::-webkit-scrollbar {
    display: none;
}
input,
textarea,
select {
    font-family: "poppins_regular";
    font-weight: 500;
}
a {
    color: #000;
    cursor: pointer;
}
img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.5em;
    font-size: 16px;
    font-family: "poppins_regular";
    color: #999;
}

.wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1350px;
}
body::-webkit-scrollbar {
    display: none;
}

.learn-button a {
    background-color: #5bba64;
    display: inline-block;
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 30px;
    color: #fff;
}

/* FONTS */

@font-face {
    font-family: "poppins_bold";
    src: url("../fonts//Poppins-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_medium";
    src: url("../fonts/Poppins-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_light";
    src: url("../fonts//Poppins-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_regular";
    src: url("../fonts//Poppins-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_semibold";
    src: url("../fonts/Poppins-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* SLIDER */

.slick-slider .slick-list {
    max-height: 380px !important;
    max-width: 700px !important;
}
.slick-slide {
    width: 338px !important;
}

@media all and (max-width: 768px) {
    .slick-slide {
        width: 318px !important;
    }
}
@media all and (max-width: 480px) {
    .slick-slide {
        width: 280px !important;
    }
}

.pagination {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.pagination .pagination_break_li,
.pagination .pagination__li {
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    cursor: pointer;
}
.pagination .pagination__li a,
.pagination .pagination_break_a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
}
.pagination .pagination_li_active {
    border-radius: 8px;
    background: #6347f9;
    border: 1px solid #d3d3d3;
}
.pagination .pagination_li_active a {
    color: #fff;
}

.pagination .pagination_next_li,
.pagination .pagination_previous_li {
}
.pagination .pagination_previousli .paginationprevious_a,
.pagination .pagination_nextli .paginationnext_a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
    cursor: pointer;
}
.pagination .pagination_disabled_btns {
    opacity: 0.4;
}
.pagination_next_a {
    color: #6347f9;
    border: none !important ;
}
